.icon {
  margin: auto;
  margin-left: 5px;
}

.loginArea {
  padding: 10px;
  background: #bbdeff;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  font-weight: 600;
}

.left {
  background: rgb(63, 161, 255);
  background: linear-gradient(
    270deg,
    rgba(63, 161, 255, 1) 0%,
    rgba(103, 180, 255, 1) 77%,
    rgba(144, 200, 255, 1) 100%
  );
  padding: 10px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

:--desktop-device .left {
  padding-left: 30vh;
  padding-right: 30vh;
}

.welcome {
  color: #fff;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 31px;
}

.returnLogin {
  color: #fff;
}

.returnLogin:hover {
  color: rgb(41, 85, 163) !important;
}

.wrapperrecover {
  width: 100%;
  text-align: center;
}

.rounded {
  border-radius: 10px;
}

.login {
  width: 100%;
  background: #50ff41;
  border-color: #50ff41;

  &:hover,
  &:active,
  &:focus {
    background: #50ff41;
    border-color: #50ff41;
  }
}

.right {
  background: #1288fb;
  padding: 10px;
  height: 100vh;
  padding-left: 30vh;
  padding-right: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
