h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}

.landing {
    display: flex;
   
}

.submotivation{
    font-size: 20px;
    height: calc(100% - 170px);
    display: flex;
    align-items: center;
    color: rgb(0, 0, 0);
    font-weight: bold;
}

:--desktop-device .motivation {
    font-size: 40px;
    width: calc(100% - 170px);
    display: flex;
    align-items: center;
    color: rgb(0, 0, 0);
    font-weight: bold;
}

:--mobile-device .motivation {
    font-size: 40px;
    width: calc(100% - 170px);
    display: flex;
    align-items: center;
    color: rgb(124, 124, 1);
    font-weight: bold;
}
